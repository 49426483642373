import { Link } from 'react-router-dom'

export const PageHome = () => {
	return (
		<div>
			<Link to="/login">/login</Link>
			<br />
			<Link to="/profile">/profile</Link>
			<br />
			<Link to="/teams">/teams</Link>
			<br />
			<Link to="/sac">/sac</Link>
			<br />
			<Link to="/games">/games</Link>
			<br />
			<Link to="/analyze">/analyze</Link>
			<br />
			<Link to="/report">/report</Link>
		</div>
	)
}
