const BASE_API_URL = 'https://latent.lgpd.express/api'
// const BASE_API_URL = 'http://localhost:5000/api'

const defaultFetch = <T>(promise: Promise<Response>): Promise<T> => {
	return new Promise<T>((resolve, reject) => {
		promise.then(res => {
			if (res.ok) {
				res
					.json()
					.then(json => resolve(json))
					.catch(err => resolve(null))
			} else {
				res
					.json()
					.then(reject)
					.catch(() => reject(res))
			}
		})
	})
}

const fetchGet = <T>(path: string) =>
	defaultFetch<T>(
		fetch(`${BASE_API_URL}${path}`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
	)

const fetchDelete = <T>(path: string) =>
	defaultFetch<T>(
		fetch(`${BASE_API_URL}${path}`, {
			method: 'DELETE',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
	)

const fetchPost = <T>(path: string, body: string) =>
	defaultFetch<T>(
		fetch(`${BASE_API_URL}${path}`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body,
		})
	)

const fetchPostFormData = <T>(path: string, formData: FormData) =>
	defaultFetch<T>(
		fetch(`${BASE_API_URL}${path}`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
			},
			body: formData,
		})
	)

export const api = {
	login: (auth: { username: string; password: string }) => fetchPost<LoggedUser>('/login', JSON.stringify(auth)),
	loggedUser: () => fetchGet<LoggedUser>('/logged-user'),
	findTeams: () => fetchGet<TeamFindDTO[]>('/teams/find-all'),
	findTeamsForSelect: () => fetchGet<TeamFindForSelectDTO[]>('/teams/find-all-for-select'),
	getReport: (filters: ReportFiltersDTO) => fetchPost<ReportDTO>('/moves-reports/all-info', JSON.stringify(filters)),
	findTeamsOrder: (order: string) => fetchGet<TeamFindDTO[]>(`/teams/find-all-order?order=${order}`),
	createOrUpdateTeam: (formTeam: CreateUpdateTeamDTO) => fetchPost<TeamFindDTO>('/teams/create-or-update', JSON.stringify(formTeam)),
	getGames: () => fetchGet<GameFindDTO[]>('/games/find-all'),
	getAccount: () => fetchGet<Account>('/accounts/account'),
	createOrUpdateGame: (dto: CreateUpdateGameDTO) => fetchPost<void>('/games/create-or-update', JSON.stringify(dto)),
	uploadImage: (formData: FormData, type: 'logo') => fetchPostFormData<{ filename: string }>(`/upload-image/${type}`, formData),
	updateAccount: (form: UpdateAccountDTO) => fetchPost('/accounts/update-account', JSON.stringify(form)),
	createOrUpdatePlayer: (formPlayer: CreateUpdatePlayerDTO) => fetchPost('/players/create-or-update', JSON.stringify(formPlayer)),
	getPlayersForSelect: () => fetchGet<{ id: number; name: string }[]>('/players/find-players-for-select'),
	getTeamPlayers: (teamId: number) => fetchGet<PlayerTeamFindDTO[]>(`/players/find-team-players?teamId=${teamId}`),
	getCategories: () => fetchGet<Category[]>('/categories/find-all'),
	deleteTeamPlayer: (playerId: number, teamId: number) => fetchDelete(`/players/delete-team-player?playerId=${playerId}&teamId=${teamId}`),
	deleteTeam: (id: number) => fetchDelete(`/teams/delete-team?id=${id}`),
	deleteMove: (id: number) => fetchDelete(`/moves/remove-move?id=${id}`),
	removerPlayer: (id: number) => fetchPost(`/players/remove-player?id=${id}`, ''),
	getPositions: () => fetchGet<Position[]>('/positions/find-all'),
	getSac: () => fetchGet<Sac[]>('/sac/find-all'),
	getSetting: () => fetchGet<Setting>('/settings/find-all'),
	removerGame: (id: number) => fetchPost(`/games/remove-game?id=${id}`, ''),
	getMovesTypes: () => fetchGet<MoveTypeDTO[]>('/move-types/find-all'),
	getGamesParams: (gender: string, type: string, mainTeamId: string, adversaryTeamId: string) =>
		fetchGet<GameFindDTO[]>(`/games/find-games-params?gender=${gender}&type=${type}&mainTeamId=${mainTeamId}&adversaryTeamId=${adversaryTeamId}`),
	createOrUpdateMove: (dto: CreateOrUpdateMoveDTO) => fetchPost<MoveDTO>('/moves/create-or-update', JSON.stringify(dto)),
	getGameMoves: (gameId: number) => fetchGet<MoveDTO[]>(`/moves/find-by-game?gameId=${gameId}`),
	updateStatusGame: (id: number, status: string) => fetchPost<GameFindDTO>(`/games/update-status-game?id=${id}&status=${status}`, null),
	getComparePlayers: (filters: ReportFiltersDTO) => fetchPost<ReportDTO>('/moves-reports/comapre-players', JSON.stringify(filters)),
	updatePlayerGameInfo: (body: UpdatePlayerGameInfoDTO) => fetchPost<void>('/games/update-player-info', JSON.stringify(body)),
	getGameForAnalyze: (query: FindGameForAnalyzeQueryDTO) =>
		fetchGet<GameForAnalyzeDTO>('/games/find-game-for-analyze?' + new URLSearchParams(query).toString()),
}
