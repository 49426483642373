import toast, { DefaultToastOptions, Renderable, ValueOrFunction } from 'react-hot-toast'

export const toastSaving = <T>(
	promise: Promise<T>,
	msgs?: {
		loading?: Renderable
		success?: ValueOrFunction<Renderable, T>
		error?: ValueOrFunction<Renderable, any>
	},
	opts?: DefaultToastOptions | undefined
) =>
	toast.promise(promise, {
		loading: msgs?.loading || 'Salvando...',
		success: msgs?.success || 'Salvo com sucesso',
		error: msgs?.error || (err => (!err ? 'Erro ao salvar' : err.message ? err.message : JSON.stringify(err))),
	})

export const toastError = (message: string) => toast.error(message)
