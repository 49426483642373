import styled from 'styled-components'

export const FormTeam = styled.form`
	display: flex;
	gap: 5px;
	margin-bottom: 32px;

	.form-group-gender {
		max-width: 250px;
	}

	.form-group-category,
	.form-group-name {
		max-width: 300px;
	}

	& > div {
		flex-grow: 1;
	}
`
export const ManagePlayersContainer = styled.div`
	display: grid;
	grid-template-columns: calc(30% - 20px) calc(30% - 20px) 40%;
	gap: 20px;

	.table-teams-wrapper {
		flex-grow: 1;
	}

	.form-player-wrapper {
		flex-grow: 3;

		form {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}

		.form-group {
			display: flex;
			align-items: center;

			label {
				min-width: 85px;
			}
		}

		.btn-save-player-wrapper {
			display: flex;
			justify-content: end;
		}

		#btn-new-player {
			padding-top: 0px;
			padding-bottom: 0px;
			height: 25px;
		}
	}

	.table-players-wrapper {
		flex-grow: 4;
	}
`
