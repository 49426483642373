import * as S from './ReportMovesTable.styles'
import ReactPlayer from 'react-player'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { api } from '../services/api'
import { formatTime } from '../utils'

type ReportMovesTableProps = {
	report: ReportDTO
}

export const ReportMovesTable = ({ report }: ReportMovesTableProps) => {
	let playerRef = null
	const [game, setGame] = useState(null)
	const [isModalPlayer, setModalPLayer] = useState(false)
	const [moves, setMoves] = useState<CreateOrUpdateMoveDTO | any>([])

	const renderMoves = (moves: ReportMoveDTO[][]) => {
		return moves.map((move, i) => (
			<>
				{move.map(m => (
					<tr key={m.moveTypeId} className={`bg-${m.type}`}>
						<td>{m.name}</td>
						<td>{m.type === 'percentage' ? (m.max || 0).toFixed(1) + '%' : m.max}</td>
						<td>{m.type === 'percentage' ? (m.avg || 0).toFixed(1) + '%' : m.avg}</td>
						<td className={m.type === 'percentage' ? 'bg-percentage-empty' : ''}>{m.total}</td>
						<td className="empty"></td>
					</tr>
				))}

				{i < moves.length - 1 ? (
					<tr className="empty">
						<td colSpan={4} className="empty"></td>
					</tr>
				) : null}
			</>
		))
	}

	async function starPlay(move) {
		playerRef.seekTo(move.timeBegin, 'seconds')
	}

	const renderMovesFromGames = (moves: ReportMoveDTO[][]) => {
		return moves.map((move, i) => (
			<>
				{move.map(m => (
					<tr key={m.moveTypeId} className={`bg-${m.type}`}>
						{m.games.map((value, i) => (
							<td key={i}>{m.type === 'percentage' ? (value || 0).toFixed(1) + '%' : value}</td>
						))}
					</tr>
				))}

				{i < moves.length - 1 ? (
					<tr className="empty">
						<td colSpan={report.games.length} className="empty"></td>
					</tr>
				) : null}
			</>
		))
	}

	useEffect(() => {
		async function getMoves() {
			if (game) {
				const moves = await api.getGameMoves(game.id)

				setMoves(moves)
			}
		}

		getMoves()
	}, [game])

	async function handleVideoPlayer(game) {
		setGame(game)
		setModalPLayer(true)
	}

	return (
		<div style={{ display: 'grid', gridTemplateColumns: '485px auto' }}>
			<div style={{}}>
				<S.Table className="">
					<tbody>
						<tr className="header">
							<td rowSpan={3} className="bg-black"></td>
							<td rowSpan={2} className="bg-orange">
								ESTATÍSTICAS
							</td>
							<td rowSpan={2} colSpan={2}>
								Nº PARTIDAS JOGADAS
							</td>
							<td rowSpan={2}>{report.games.length}</td>
							<td className="empty">&nbsp;</td>
						</tr>
						<tr className="header">
							<td className="empty">&nbsp;</td>
						</tr>
						<tr className="header">
							<td>JOGADAS</td>
							<td className="bg-black">90 MIN</td>
							<td className="bg-black">MÉDIA</td>
							<td className="bg-black">TOTAL</td>
							<td className="empty">&nbsp;</td>
						</tr>
						<tr className="empty">
							<td colSpan={4} className="empty"></td>
						</tr>
						<tr>
							<td
								rowSpan={report.moves.fieldPlayers.length + report.moves.fieldPlayers.reduce((agg, move) => agg + move.length, 0)}
								className="vertical-title bg-orange"
							>
								JOGADORES DE LINHA
							</td>
						</tr>

						{renderMoves(report.moves.fieldPlayers)}

						<tr className="empty">
							<td colSpan={4} className="empty"></td>
						</tr>

						<tr>
							<td
								rowSpan={report.moves.goalKeepers.length + report.moves.goalKeepers.reduce((agg, move) => agg + move.length, 0)}
								className="vertical-title bg-orange"
							>
								GOLEIROS
							</td>
						</tr>

						{renderMoves(report.moves.goalKeepers)}

						{report.moves.customMoves.length === 0 ? null : (
							<>
								<tr className="empty">
									<td colSpan={4} className="empty"></td>
								</tr>

								<tr>
									<td
										rowSpan={report.moves.goalKeepers.length + report.moves.goalKeepers.reduce((agg, move) => agg + move.length, 0)}
										className="vertical-title bg-orange"
									></td>
								</tr>
								{renderMoves(report.moves.customMoves)}
							</>
						)}
					</tbody>
				</S.Table>
			</div>
			<div style={{ minWidth: 150, width: '100%', overflow: 'auto', height: 'fit-content' }}>
				<S.Table>
					<tbody>
						<tr className="header">
							{report.games.map(g => (
								<td key={g.id} className={`status-${g.status}`}>
									{g.status}
								</td>
							))}
						</tr>
						<tr className="header">
							{report.games.map(g => (
								<td key={g.id} className={`status-${g.status}`}>
									{g.score}
								</td>
							))}
						</tr>
						<tr className="header">
							{report.games.map(g => (
								<td key={g.id} className="bg-orange" style={{ cursor: 'pointer' }} onClick={() => handleVideoPlayer(g)}>
									{g.name}
								</td>
							))}
						</tr>

						<tr className="empty">
							<td colSpan={report.games.length} className="empty"></td>
						</tr>

						{renderMovesFromGames(report.moves.fieldPlayers)}

						<tr className="empty">
							<td colSpan={report.games.length} className="empty"></td>
						</tr>

						{renderMovesFromGames(report.moves.goalKeepers)}

						{report.moves.customMoves.length === 0 ? null : (
							<>
								<tr className="empty">
									<td colSpan={report.games.length} className="empty"></td>
								</tr>

								{renderMovesFromGames(report.moves.customMoves)}
							</>
						)}
					</tbody>
				</S.Table>
			</div>
			{game && isModalPlayer ? (
				<Modal
					isOpen={true}
					// onAfterOpen={afterOpenModal}
					ariaHideApp={false}
					onRequestClose={() => setModalPLayer(false)}
					// style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2>Jogadas contra {game.name}</h2>
						<button onClick={() => setModalPLayer(false)} style={{ backgroundColor: '#FFF', border: 0, fontWeight: 'bold', fontSize: 32 }}>
							X
						</button>
					</div>
					<hr />
					<div style={{ display: 'block', padding: '32px 32px', alignItems: 'center', justifyContent: 'center' }}>
						<div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
							<ReactPlayer
								// onPause={() => handleOpenModal()}
								ref={player => (playerRef = player)}
								width={'100%'}
								height={540}
								url={game ? game.url : null}
								playing={true}
								controls={true}
							/>
						</div>
						<>
							<hr />
							<h3 className="text-center">LISTA DE JOGADAS</h3>
							<hr />

							<div>
								<div className="table-teams-wrapper">
									<table className="table table-sm table-striped table-hover table-with-selected-indicator">
										<thead>
											<tr>
												<th>ID</th>
												<th>Inicio</th>
												<th>Fim</th>
												<th>Jogador</th>
												<th>Jogada</th>
												{/* <th>Tipo</th>
													<th>Adversário</th> */}
												<th>Ver jogada</th>
											</tr>
										</thead>
										<tbody>
											{moves.map(move => (
												<tr key={move.id}>
													<td>{move.id}</td>
													<td>{formatTime(move.timeBegin)}</td>
													<td>{formatTime(move.timeEnd)}</td>
													<td>{move.player.name}</td>
													<td>{move.moveType.name}</td>
													<td>
														<div>
															<button onClick={() => starPlay(move)}>Iniciar jogada</button>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</>
					</div>
				</Modal>
			) : null}
		</div>
	)
}
