import styled from 'styled-components'

export const InputsWrapper = styled.div`
	max-width: 520px;
	label {
		display: inline-block;
		width: 220px;
	}
	input {
		display: inline-block;
		width: 300px;
		margin-bottom: 5px;
	}
`

export const InputWithBoxWrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;

	span {
		margin: auto 0 auto 50px;
	}
`

export const ColorPreviewBox = styled.div`
	display: inline-block;
	cursor: pointer;
	width: 50px;
	height: 50px;
	border: 1px solid black;
`

export const ImgLogo = styled.img`
	width: 50px;
	height: 50px;
`

export const ColorPickerWrapper = styled.div`
	position: absolute;
`
