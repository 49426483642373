import styled from 'styled-components'

export const ReportsContainer = styled.div`
	width: 100%;
	display: flex;

	& > div {
		flex-grow: 1;
	}
`

export const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 50px;
`
export const FiltersContainer = styled.div`
	display: grid;
	grid-template-columns: 168px 300px 162px 220px 1fr auto;
	gap: 10px;
	margin-bottom: 15px;
`
