import { useState } from '@hookstate/core'
import { useEffect, useRef } from 'react'
import { PhotoshopPicker } from 'react-color'
import { api } from '../services/api'
import { toastSaving } from '../services/toasts'
import { Loading } from './Loading'
import * as S from './PageProfile.styles'

export const PageProfile = () => {
	const form = useState<UpdateAccountDTO>(null)
	const colorPicked = useState('#fff')
	const showColorPicker = useState(false)
	const imageLogoSrc = useState('')
	const imageLogoChanged = useState(false)

	const refLogoFile = useRef<HTMLInputElement>(null)
	const refImagePreview = useRef<HTMLImageElement>(null)

	const handleInputImageChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		const input = e.target

		if (input.files && input.files[0]) {
			if (input.files[0].size > 3 * 1024 * 1024) {
				alert('Arquivo não pode ter mais que 3MB')
				input.value = ''
				return
			}
			const reader = new FileReader()

			reader.onload = e => {
				imageLogoSrc.set(e.target.result.toString())
				imageLogoChanged.set(true)
			}

			reader.readAsDataURL(input.files[0])
		}
	}

	const handleRevertImageClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		refLogoFile.current.value = ''
		imageLogoSrc.set(form.value.photo || '')
		imageLogoChanged.set(false)
	}

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		form.merge({
			[e.target.name]: e.target.value,
		})
	}

	const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault()

		let fileName = form.value.photo
		const file = refLogoFile.current?.files[0] || null

		if (file) {
			const data = new FormData()
			data.append('image', refLogoFile.current?.files[0] || null)

			try {
				const res = await toastSaving(api.uploadImage(data, 'logo'), { loading: 'Salvando imagem...', success: 'Imagem salva com sucesso' })
				fileName = res.filename
			} catch (e) {}
		}

		await toastSaving(api.updateAccount({ ...form.value, photo: fileName }), { loading: 'Salvando perfil...', success: 'Perfil salvo com sucesso' })
	}

	useEffect(() => {
		api.getAccount().then(res => {
			form.set(res)
			colorPicked.set(res.color || '#fff')
		})
	}, [])

	if (!form.value) {
		return <Loading show />
	}

	return (
		<div className="container">
			<h1>Meu Perfil</h1>
			<form onSubmit={handleFormSubmit}>
				<div>
					<S.InputWithBoxWrapper>
						<label htmlFor="image" className="d-block cursor-pointer">
							<S.ImgLogo ref={refImagePreview} src={imageLogoSrc.value || 'err'} className="cursor-pointer" />
							<span>ALTERAR LOGO</span>
						</label>
						<div>
							<button
								onClick={handleRevertImageClick}
								type="button"
								className="btn btn-danger btn-sm"
								id="btn-revert-image"
								style={{ marginLeft: 5, paddingTop: 1, paddingBottom: 1, visibility: imageLogoChanged.value ? 'visible' : 'hidden' }}
							>
								Reverter
							</button>
						</div>

						<input
							ref={refLogoFile}
							onChange={handleInputImageChange}
							type="file"
							className="d-none"
							id="image"
							name="image"
							accept=".jpg,.jpeg,.png,.gif,.webp"
						/>
					</S.InputWithBoxWrapper>
					<S.InputWithBoxWrapper>
						<S.ColorPreviewBox style={{ backgroundColor: form.value.color || '#fff' }} onClick={() => showColorPicker.set(true)} />
						<span>ALTERAR cor</span>
					</S.InputWithBoxWrapper>
					{showColorPicker.value && (
						<PhotoshopPicker
							color={colorPicked.value}
							onChange={newColor => colorPicked.set(newColor.hex)}
							onAccept={() => {
								form.merge({
									color: colorPicked.value,
								})
								showColorPicker.set(false)
							}}
							onCancel={() => {
								colorPicked.set(form.value.color || '#fff')
								showColorPicker.set(false)
							}}
						/>
					)}
					<div>
						<S.InputsWrapper>
							<label htmlFor="nameOrg">NOME DA ORGANIZAÇÃO: </label>
							<input
								type="text"
								name="nameOrg"
								id="nameOrg"
								className="form-control"
								value={form.value.nameOrg || ''}
								onChange={handleInputChange}
								required
							/>
							<label htmlFor="nameOrg">NOME REPRESENTANTE: </label>
							<input type="text" name="name" id="name" className="form-control" value={form.value.name || ''} onChange={handleInputChange} required />
							<label htmlFor="phone">TELEFONE REPRESENTANTE: </label>
							<input type="text" name="phone" id="phone" className="form-control" value={form.value.phone || ''} onChange={handleInputChange} />
							<label htmlFor="email">EMAIL REPRESENTANTE: </label>
							<input type="email" name="email" id="email" className="form-control" value={form.value.email || ''} onChange={handleInputChange} />
							<label id="password">SENHA DE ACESSO: </label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="Preencha para alterar sua senha atual"
								className="form-control"
								value={form.value.password || ''}
								onChange={handleInputChange}
							/>
						</S.InputsWrapper>
						<button type="submit" className="btn btn-primary">
							Salvar
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}
