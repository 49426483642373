export const SELECT_GENDER_OPTIONS = [
	{ label: 'Masculino', value: 'M' as const },
	{ label: 'Feminino', value: 'F' as const },
]

export const GENDER_LABEL_MAP = {
	M: 'Masculino',
	F: 'Feminino',
}

export const SELECT_GAME_TYPE_OPTIONS = [
	{ label: 'Treino', value: 'T' as const },
	{ label: 'Amistoso', value: 'F' as const },
	{ label: 'Jogo', value: 'G' as const },
]

export const EMPTY_CREATE_UPDATE_PLAYER_DTO: CreateUpdatePlayerDTO = {
	id: null,
	teamId: null,
	name: '',
	positionId: null,
	email: '',
	password: '',
	forceUpdate: false,
}

export const EMPTY_CREATE_UPDATE_TEAM_DTO: CreateUpdateTeamDTO = {
	id: null,
	name: '',
	categoryId: null,
	gender: 'M',
	isMain: true,
}

export const HttpStatus = {
	CONFLICT: 409,
}

export const EMPTY_CREATE_UPDATE_GAME_DTO: CreateUpdateGameDTO = {
	id: null,
	gender: null,
	mainTeamId: null,
	categoryId: null,
	adversaryTeamId: null,
	url: '',
	type: null,
}

export const SELECT_COMMON_OPTIONS = {
	placeholder: 'Selecione',
	loadingMessage: () => 'Carregando',
	noOptionsMessage: () => 'Sem opções',
}

export const FOOTBALL_IMG_HEIGHT = 794
export const FOOTBALL_IMG_WIDTH = 514
