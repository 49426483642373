import { useState } from '@hookstate/core'
import { useEffect } from 'react'
import Select from 'react-select'
import CloseIconSVG from '../assets/close.svg'
import {
	EMPTY_CREATE_UPDATE_PLAYER_DTO,
	EMPTY_CREATE_UPDATE_TEAM_DTO,
	GENDER_LABEL_MAP,
	HttpStatus,
	SELECT_COMMON_OPTIONS,
	SELECT_GENDER_OPTIONS,
} from '../consts'
import { api } from '../services/api'
import { toastError, toastSaving } from '../services/toasts'
import { Loading } from './Loading'
import * as S from './PageTeams.styles'

export const PageTeams = () => {
	const formTeam = useState<CreateUpdateTeamDTO>({ ...EMPTY_CREATE_UPDATE_TEAM_DTO })
	const formPlayer = useState<CreateUpdatePlayerDTO | null>(null)
	const teams = useState<TeamFindDTO[]>([])
	const players = useState<PlayerTeamFindDTO[]>([])
	const selectPositions = useState<SelectOption[]>([])
	const selectCategories = useState<SelectOption[]>([])
	const emailWithConflict = useState('')

	const playersAddedCount = useState(0)
	const teamsAddedCount = useState(0)

	const selectedPlayerIdToRemove = useState(0)
	const selectedTeamIdToRemove = useState(0)
	const selectedTeamId = useState(0)

	const loading = useState({
		categories: true,
		positions: true,
		players: false,
		savingPlayer: false,
		teams: true,
	})

	console.log('teams')
	console.log(teams)

	const deletePlayer = (id: number) => {
		toastSaving(api.deleteTeamPlayer(id, selectedTeamId.value), { loading: 'Removendo Jogador...', success: 'Jogador removido com sucesso' }).then(
			() => {
				selectedPlayerIdToRemove.set(0)
				playersAddedCount.set(prev => prev + 1)
			}
		)
	}

	const deleteTeam = (id: number) => {
		toastSaving(api.deleteTeam(id), { loading: 'Removendo Time...', success: 'Time removido com sucesso' }).then(() => {
			selectedTeamIdToRemove.set(0)
			teamsAddedCount.set(prev => prev + 1)
		})
	}

	const resetFormPlayer = () => {
		formPlayer.set({ ...EMPTY_CREATE_UPDATE_PLAYER_DTO, teamId: selectedTeamId.value })
	}

	const resetFormTeam = () => {
		formTeam.set({ ...EMPTY_CREATE_UPDATE_TEAM_DTO })
		formPlayer.set(null)
		selectedTeamId.set(0)
	}

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		formPlayer.merge({
			[e.target.name]: e.target.value,
		})
	}

	const handleFormSubmitTeam: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault()

		if (!formTeam.value.categoryId) {
			return toastError('Favor informar o TIME.')
		}

		const savedTeam = await toastSaving(api.createOrUpdateTeam({ ...formTeam.value }), {
			loading: 'Salvando Time...',
			success: 'Time salvo com sucesso',
		})

		resetFormTeam()

		teamsAddedCount.set(prev => prev + 1)
		selectedTeamId.set(savedTeam.id)
	}

	const handleFormSubmitPlayer: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		formPlayer.merge({ teamId: selectedTeamId.value })

		if (!formPlayer.value.positionId) {
			return toastError('Favor informar uma posição para o jogador.')
		}

		const email = formPlayer.value.email

		loading.merge({ savingPlayer: true })
		toastSaving(api.createOrUpdatePlayer({ ...formPlayer.value, forceUpdate: formPlayer.value.email === emailWithConflict.value }), {
			loading: 'Salvando Jogador...',
			success: 'Jogador salvo com sucesso',
		})
			.then(() => {
				resetFormPlayer()
				playersAddedCount.set(prev => prev + 1)
			})
			.catch(err => {
				if (err?.statusCode === HttpStatus.CONFLICT) {
					emailWithConflict.set(email)
				}
			})
			.finally(() => loading.merge({ savingPlayer: false }))
	}

	useEffect(() => {
		loading.merge({ teams: true })
		api
			.findTeams()
			.then(newTeams => {
				teams.set(newTeams)
			})
			.finally(() => loading.merge({ teams: false }))
	}, [teamsAddedCount.value])

	useEffect(() => {
		if (!selectedTeamId.value) return

		loading.merge({ players: true })

		api
			.getTeamPlayers(selectedTeamId.value)
			.then(newPlayers => {
				players.set(newPlayers)
			})
			.finally(() => loading.merge({ players: false }))

		resetFormPlayer()

		const selectedTeam = teams.value.find(t => t.id === selectedTeamId.value)

		formTeam.set({ ...selectedTeam })
	}, [selectedTeamId.value, playersAddedCount.value])

	useEffect(() => {
		api
			.getPositions()
			.then(newPositions => {
				selectPositions.set(newPositions.map(pos => ({ value: pos.id, label: pos.name })))
			})
			.finally(() => loading.merge({ positions: false }))

		api
			.getCategories()
			.then(newCategories => {
				selectCategories.set(newCategories.map(cat => ({ value: cat.id, label: cat.name })))
			})
			.finally(() => loading.merge({ categories: false }))
	}, [])

	return (
		<div className="container">
			<hr />
			{!formTeam.value.id ? (
				<h3 className="text-center">ADICIONAR NOVO TIME</h3>
			) : (
				<div className="d-flex justify-content-center align-items-center">
					<h3 className="text-center d-inline-block mb-0">EDITAR TIME</h3>
					<button
						type="button"
						className="bnt btn-sm btn-secondary d-inline-block ml-2 py-0"
						onClick={() => resetFormTeam()}
						style={{
							height: 25,
						}}
					>
						novo time
					</button>
				</div>
			)}
			<hr />
			<S.FormTeam onSubmit={handleFormSubmitTeam}>
				<div className="form-group form-group-gender">
					<label htmlFor="genderTeam">GÊNERO:</label>
					<Select
						id="genderTeam"
						{...SELECT_COMMON_OPTIONS}
						options={SELECT_GENDER_OPTIONS}
						value={SELECT_GENDER_OPTIONS.find(g => g.value === formTeam.value.gender) || null}
						onChange={newValue => formTeam.merge({ gender: newValue.value })}
					/>
				</div>
				<div className="form-group form-group-category">
					<label htmlFor="categoryTeam">TIME:</label>
					<Select
						id="categoryTeam"
						{...SELECT_COMMON_OPTIONS}
						isLoading={loading.value.categories}
						options={selectCategories.value}
						value={selectCategories.value.find(c => c.value === formTeam.value.categoryId) || null}
						onChange={newValue => formTeam.merge({ categoryId: newValue.value, name: newValue.label })}
					/>
				</div>
				<div className="form-group form-group-name">
					<label htmlFor="teamName">NOME:</label>
					<input type="text" className="form-control" value={formTeam.value.name} onChange={e => formTeam.merge({ name: e.target.value })} />
				</div>
				<div className="form-group">
					<label>.</label>
					<button type="submit" className="d-block btn btn-primary">
						{formTeam.value.id ? 'Alterar' : 'Criar'} Time
					</button>
				</div>
			</S.FormTeam>

			<hr />
			<h3 className="text-center">
				TIMES CRIADOS <Loading show={loading.value.teams} />
			</h3>
			<hr />
			<S.ManagePlayersContainer>
				<div className="table-teams-wrapper">
					<table className="table table-sm table-striped table-hover table-with-selected-indicator">
						<thead>
							<tr>
								<th>GÊNERO</th>
								<th>TIPO</th>
								<th>NOME</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{!teams.value.length && !loading.value.teams && (
								<tr>
									<td colSpan={5} className="text-center">
										Nenhum time cadastrado
									</td>
								</tr>
							)}
							{teams.value.map(team => (
								<tr key={team.id}>
									{selectedTeamIdToRemove.value === team.id ? (
										<td colSpan={5}>
											<div className="d-flex justify-content-between">
												<button type="button" className="btn btn-sm btn-warning" onClick={() => deleteTeam(team.id)}>
													Confirmar exclusão!
												</button>
												<button type="button" className="btn btn-sm btn-success" onClick={() => selectedTeamIdToRemove.set(0)}>
													Cancelar
												</button>
											</div>
										</td>
									) : (
										<>
											<td className="position-relative" onClick={() => selectedTeamId.set(team.id)}>
												<i className={`selected-indicator ${selectedTeamId.value === team.id ? 'active' : ''}`} /> {GENDER_LABEL_MAP[team.gender]}
											</td>
											<td onClick={() => selectedTeamId.set(team.id)}>{team.name}</td>
											<td onClick={() => selectedTeamId.set(team.id)}>{team.category?.name}</td>
											<td className="p-0">
												<div className="d-flex justify-content-end w-100 h-100">
													<button
														type="button"
														title="Deletar!"
														className="btn btn-sm btn-danger hidden-when-not-hovered text-center bg-danger"
														onClick={() => selectedTeamIdToRemove.set(team.id)}
													>
														<img src={CloseIconSVG} width={20} />
													</button>
												</div>
											</td>
										</>
									)}
								</tr>
							))}
						</tbody>
					</table>
				</div>

				{!formPlayer.ornull ? (
					<h5 className="mt-3">Para adicionar jogadores, selecione um time na tabela ao lado</h5>
				) : (
					<>
						<div className="form-player-wrapper">
							<div className="d-flex justify-content-between">
								{!formPlayer.value.id ? (
									<h5>ADICIONAR NOVO ATLETA</h5>
								) : (
									<>
										<h5>EDITAR ATLETA</h5>
										<button id="btn-new-player" type="button" className="bnt btn-sm btn-secondary" onClick={() => resetFormPlayer()}>
											novo atleta
										</button>
									</>
								)}
							</div>

							<form onSubmit={handleFormSubmitPlayer}>
								<div className="form-group">
									<label htmlFor="nameJogador">NOME:</label>
									<input
										type="text"
										name="name"
										id="nameJogador"
										className="form-control"
										value={formPlayer.value.name}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="form-group">
									<label htmlFor="position">POSIÇÃO:</label>
									<Select
										id="position"
										{...SELECT_COMMON_OPTIONS}
										options={selectPositions.value}
										value={selectPositions.value.find(pos => pos.value === formPlayer.value.positionId) || null}
										onChange={newValue => formPlayer.merge({ positionId: newValue.value })}
										className="w-100"
									/>
								</div>

								<div className="form-group">
									<label htmlFor="email">EMAIL:</label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control"
										value={formPlayer.value.email}
										onChange={handleInputChange}
										required
									/>
								</div>

								<div className="form-group">
									<label id="password">SENHA:</label>
									<input
										type="text"
										name="password"
										id="password"
										placeholder={formPlayer.value.id ? 'Preencha para alterar sua senha atual' : ''}
										className="form-control"
										value={formPlayer.value.password}
										onChange={handleInputChange}
										required
									/>
								</div>

								{emailWithConflict.value && emailWithConflict.value === formPlayer.value.email && (
									<div className="form-group d-flex flex-column align-items-start">
										<p className="bg-warning w-100 my-1 p-1">Jogador já existe com outro nome</p>
										<p className="bg-warning w-100 my-1 p-1">Clique novamente em salvar para confirmar alteração do nome</p>
									</div>
								)}

								<div className="form-group btn-save-player-wrapper">
									<button type="submit" className="btn btn-primary" disabled={loading.value.savingPlayer}>
										Salvar
									</button>
								</div>
							</form>
						</div>

						<div className="table-players-wrapper">
							<h5>
								ATLETAS ADICIONADOS <Loading show={loading.value.players} />
							</h5>

							<table className="table table-sm table-striped table-hover table-with-selected-indicator">
								<thead>
									<tr>
										<th>NOME</th>
										<th>POSIÇÃO</th>
										<th>E-MAIL</th>
										<th>SENHA</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{!players.value.length && !loading.value.players && (
										<tr>
											<td colSpan={5} className="text-center">
												Nenhum atleta cadastrado
											</td>
										</tr>
									)}
									{players.value.map(player => (
										<tr key={player.id}>
											{selectedPlayerIdToRemove.value === player.id ? (
												<td colSpan={5}>
													<div className="d-flex justify-content-between">
														<button type="button" className="btn btn-sm btn-warning" onClick={() => deletePlayer(player.id)}>
															Confirmar exclusão!
														</button>
														<button type="button" className="btn btn-sm btn-success" onClick={() => selectedPlayerIdToRemove.set(0)}>
															Cancelar
														</button>
													</div>
												</td>
											) : (
												<>
													<td className="position-relative" onClick={() => formPlayer.set(playerFindToFormPlayerDTO(player))}>
														<i className={`selected-indicator ${player.id === formPlayer.value?.id ? 'active' : ''}`} /> {player.name}
													</td>
													<td onClick={() => formPlayer.set(playerFindToFormPlayerDTO(player))}>{player.positionName}</td>
													<td onClick={() => formPlayer.set(playerFindToFormPlayerDTO(player))}>{player.email}</td>
													<td onClick={() => formPlayer.set(playerFindToFormPlayerDTO(player))}>{player.password}</td>
													<td className="p-0">
														<div className="d-flex justify-content-end w-100 h-100">
															<button
																type="button"
																title="Deletar!"
																className="btn btn-sm btn-danger hidden-when-not-hovered text-center bg-danger"
																onClick={() => selectedPlayerIdToRemove.set(player.id)}
															>
																<img src={CloseIconSVG} width={20} />
															</button>
														</div>
													</td>
												</>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</>
				)}
			</S.ManagePlayersContainer>
		</div>
	)
}

const playerFindToFormPlayerDTO = (player: PlayerTeamFindDTO): CreateUpdatePlayerDTO => ({
	id: player.id,
	teamId: player.teamId,
	name: player.name,
	positionId: player.positionId,
	email: player.email,
	password: player.password,
	forceUpdate: false,
})
