import * as S from './ReportComparePlayers.styles'

type ReportComparePlayersProps = {
	report: ReportCompareDTO[]
}

export const ReportComparePlayers = ({ report }: ReportComparePlayersProps) => {
	const renderMoves = (moves: ReportMoveDTO[][]) => {
		return moves.map((move, i) => {
			return (
				
			<>
				{move.map((m,j) => (
					<tr key={m.moveTypeId} className={`bg-${m.type}`}>
						<td>{m.name}</td>
						{report.map(player => {
							return (
								<td>{player.moves[i][j].type === 'percentage' ? (player.moves[i][j].max || 0).toFixed(1) + '%' : player.moves[i][j].max}</td>
							)
						})}
					</tr>
				))}
				{/* {report.map(players => {
					console.log(players)
					return players.moves[i].map((mp, i) => {
						return (
						
						)
					})
				})} */}

				{i < moves.length - 1 ? (
					<tr className="empty">
						<td colSpan={4} className="empty"></td>
					</tr>
				) : null}
			</>
		)})
	}

	return (
		<div style={{ display: 'grid', padding: '20px', gridTemplateColumns: 'auto' }}>
			<div style={{ minWidth: 485 }}>
				<S.Table className="">
					<tbody>
						<tr className="header">
							 {/* <td rowSpan={3} className="bg-black"></td>  */}
							<td rowSpan={1} colSpan={3} 
                style={{width: '100%', height: '56px', background: '#198754', 
                  color: "white", fontWeight: 'bold', fontSize: 28}}>	
                    COMPARAÇÃO DE ATLETAS 
              </td>
							{/* <td rowSpan={2} colSpan={2}>
								Nº PARTIDAS JOGADAS
							</td>
							<td rowSpan={2}>{report.games.length}</td> 
							<td className="empty">&nbsp;</td>  */}
						</tr>
			
						<tr className="">
							<td style={{fontSize: 18, fontWeight: 'bold'}}>JOGADAS</td>
							{report.map(player => (
								<td style={{fontSize: 18, fontWeight: 'bold'}}>{player.name}</td>

							))} 
						</tr>
				
						{report.map(player => renderMoves(player.moves))}
					</tbody>
				</S.Table>
			</div>
		</div>
	)
}
