import { useState } from '@hookstate/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Select, { ActionMeta, OnChangeValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { EMPTY_CREATE_UPDATE_GAME_DTO, SELECT_GAME_TYPE_OPTIONS, SELECT_GENDER_OPTIONS } from '../consts'
import { api } from '../services/api'
import { toastSaving } from '../services/toasts'
import * as S from './PageGames.styles'

export const PageGame = () => {
	const selectCategories = useState<SelectOption[]>([])
	const selectMainTeams = useState<SelectOption[]>([])
	const selectAdversaryTeams = useState<SelectOption[]>([])
	const selectCategoriesQuery = useState<SelectOption[]>([])
	const selectMainTeamsQuery = useState<SelectOption[]>([])
	const selectAdversaryTeamsQuery = useState<SelectOption[]>([])
	const games = useState<GameFindDTO[]>([])
	const addGame = useState(0)
	const selectGender = [...SELECT_GENDER_OPTIONS]
	const selectGenderQuery = [...SELECT_GENDER_OPTIONS]
	const selectGameType = [...SELECT_GAME_TYPE_OPTIONS]
	const selectGametypeQuery = [...SELECT_GAME_TYPE_OPTIONS]
	const selectedGameId = useState(0)
	const selectedGameIdToRemove = useState(0)
	const genderQuery = useState('')
	const typeQuery = useState('')
	const mainTeamIdQuery = useState('')
	const adversaryTeamIdQuery = useState('')
	const createAdversaryTeam = useState<CreateUpdateTeamDTO>({ gender: 'M', isMain: false })
	const addAdversario = useState(0)

	const navigate = useNavigate()

	const form = useState<CreateUpdateGameDTO>({
		id: null,
		gender: null,
		mainTeamId: null,
		categoryId: null,
		adversaryTeamId: null,
		url: '',
		type: null,
	})

	const formQuery = useState<CreateUpdateGameDTO>({
		gender: null,
		mainTeamId: null,
		categoryId: null,
		adversaryTeamId: null,
		url: '',
		type: null,
	})

	const loading = useState({
		categories: true,
		mainTeams: true,
		adversaryTeams: true,
	})

	const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault()

		try {
			const res = await toastSaving(api.createOrUpdateGame(form.value), { loading: 'Salvando...', success: 'Salvo com sucesso' })
			addGame.set(addGame.value + 1)
			resetFormGame()
		} catch (e) {}
	}

	useEffect(() => {
		api
			.getCategories()
			.then(newCategories => {
				selectCategories.set(newCategories.map(c => ({ value: c.id, label: c.name })))
				selectCategoriesQuery.set(newCategories.map(c => ({ value: c.id, label: c.name })))
			})
			.finally(() => loading.merge({ categories: false }))

		api
			.findTeams()
			.then(newTeams => {
				selectMainTeams.set(newTeams.map(t => ({ value: t.id, label: t.name })))
				selectMainTeamsQuery.set(newTeams.map(t => ({ value: t.id, label: t.name })))
			})
			.finally(() => loading.merge({ mainTeams: false }))
		api
			.findTeamsOrder('ASC')
			.then(newTeams => {
				selectAdversaryTeams.set(newTeams.map(t => ({ value: t.id, label: t.name })))
				selectAdversaryTeamsQuery.set(newTeams.map(t => ({ value: t.id, label: t.name })))
			})
			.finally(() => loading.merge({ adversaryTeams: false }))
	}, [addAdversario.value])

	const resetFormGame = () => {
		form.set({ ...EMPTY_CREATE_UPDATE_GAME_DTO })
	}

	const removeGame = (id: number) => {
		toastSaving(api.removerGame(id), { loading: 'Removendo Partida...', success: 'Partida removido com sucesso' }).then(() => {
			selectedGameIdToRemove.set(0)
			addGame.set(addGame.value + 1)
		})
	}

	useEffect(() => {
		api.getGames().then(games.set)
	}, [addGame.value])

	function findGamesParams() {
		api.getGamesParams(genderQuery.value, typeQuery.value, mainTeamIdQuery.value, adversaryTeamIdQuery.value).then(games.set)
	}

	const CreatableSelecthandleChange = (newValue: OnChangeValue<any, false>, actionMeta: ActionMeta<any>) => {
		if (actionMeta.action === 'create-option') {
			loading.merge({ adversaryTeams: true, mainTeams: true })
			createAdversaryTeam.name.set(newValue.value)
			api
				.createOrUpdateTeam(createAdversaryTeam.value)
				.then(newTeam => {
					form.merge({ adversaryTeamId: newTeam.id })
				})
				.finally(() => loading.merge({ adversaryTeams: false, mainTeams: false }))
			addAdversario.set(addAdversario.value + 1)
		} else {
			form.merge({ adversaryTeamId: newValue.value })
		}
	}

	return (
		<div className="container">
			<hr />
			<h3 className="text-center">CRIAR NOVA PARTIDA</h3>
			<hr />
			<S.Form onSubmit={handleFormSubmit}>
				<div className="form-group">
					<label>Gênero</label>
					<Select
						options={selectGender}
						value={selectGender.find(g => g.value === form.value.gender)}
						onChange={newValue => form.merge({ gender: newValue.value })}
					/>
				</div>
				<div className="form-group">
					<label>Time</label>
					<Select
						isLoading={loading.value.mainTeams}
						options={selectMainTeams.value}
						value={selectMainTeams.value.find(t => t.value === form.value.mainTeamId)}
						onChange={newValue => form.merge({ mainTeamId: newValue.value })}
					/>
				</div>
				<div className="form-group">
					<label>Tipo</label>
					<Select
						options={selectGameType}
						value={selectGameType.find(c => c.value === form.value.type)}
						onChange={newValue => form.merge({ type: newValue.value })}
					/>
				</div>
				<div className="form-group">
					<label>Adversário</label>
					<CreatableSelect
						isLoading={loading.value.adversaryTeams}
						options={selectAdversaryTeams.value}
						value={selectAdversaryTeams.value.find(t => t.value === form.value.adversaryTeamId)}
						onChange={CreatableSelecthandleChange}
					/>
				</div>
				<div className="form-group">
					<label>URL</label>
					<input type="text" className="form-control" value={form.value.url} onChange={e => form.merge({ url: e.target.value })} />
				</div>
				<div className="form-group">
					<label>.</label>
					<button type="submit" className="d-block btn btn-success">
						SALVAR
					</button>
				</div>
				<div className="form-group">
					<label> </label>
					{form.value.id ? (
						<button type="button" className="d-block btn btn-secondary" onClick={() => resetFormGame()}>
							NOVO
						</button>
					) : (
						<></>
					)}
				</div>
			</S.Form>

			<hr />

			<div>
				<h4>Partidas criadas</h4>

				<S.Form onSubmit={handleFormSubmit}>
					<div className="form-group">
						<label>Gênero</label>
						<Select
							isClearable={true}
							options={selectGenderQuery}
							value={selectGenderQuery.find(g => g.value === formQuery.value.gender)}
							onChange={newValue => (newValue ? genderQuery.set(newValue.value) : genderQuery.set(null))}
						/>
					</div>
					<div className="form-group">
						<label>Time</label>
						<Select
							isClearable={true}
							isLoading={loading.value.mainTeams}
							options={selectMainTeamsQuery.value}
							value={selectMainTeamsQuery.value.find(t => t.value === formQuery.value.mainTeamId)}
							onChange={newValue => (newValue ? mainTeamIdQuery.set(newValue.value) : mainTeamIdQuery.set(null))}
						/>
					</div>
					<div className="form-group">
						<label>Tipo</label>
						<Select
							isClearable={true}
							options={selectGametypeQuery}
							value={selectGametypeQuery.find(c => c.value === formQuery.value.type)}
							onChange={newValue => (newValue ? typeQuery.set(newValue.value) : typeQuery.set(null))}
						/>
					</div>
					<div className="form-group">
						<label>Adversário</label>
						<Select
							isClearable={true}
							isLoading={loading.value.adversaryTeams}
							options={selectAdversaryTeamsQuery.value}
							value={selectAdversaryTeamsQuery.value.find(t => t.value === formQuery.value.adversaryTeamId)}
							onChange={newValue => (newValue ? adversaryTeamIdQuery.set(newValue.value) : adversaryTeamIdQuery.set(null))}
						/>
					</div>
					<div className="form-group">
						<label>.</label>
						<button onClick={() => findGamesParams()} type="button" className="d-block btn btn-success">
							FILTRAR
						</button>
					</div>
				</S.Form>
				<S.ManageGamesContainer>
					<div className="table-teams-wrapper">
						<table className="table table-sm table-striped table-hover table-with-selected-indicator">
							<thead>
								<tr>
									<th>Gênero</th>
									<th>Time</th>
									<th>Tipo</th>
									<th>Adversário</th>
									<th>Analisar</th>
									<th>Relatório</th>
									<th>Situação</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{!games.value.length && (
									<tr>
										<td colSpan={8} className="text-center">
											Nenhuma partida cadastrada.
										</td>
									</tr>
								)}
								{games.value.map(game => (
									<tr key={game.id}>
										{selectedGameIdToRemove.value === game.id ? (
											<td colSpan={8}>
												<div className="d-flex justify-content-between">
													<button type="button" className="btn btn-sm btn-danger" onClick={() => removeGame(game.id)}>
														Confirmar exclusão!
													</button>
													<button type="button" className="btn btn-sm btn-success" onClick={() => selectedGameIdToRemove.set(0)}>
														Cancelar
													</button>
												</div>
											</td>
										) : (
											<>
												<td className="position-relative" onClick={() => selectedGameId.set(game.id)}>
													<i className={`selected-indicator ${game.id === form.value?.id ? 'active' : ''}`} />
													{SELECT_GENDER_OPTIONS.find(g => g.value === game.gender).label}
												</td>
												<td onClick={() => form.set(gameFindToFormGameDTO(game))} className="position-relative">
													{game.mainTeam.name}
												</td>
												<td onClick={() => form.set(gameFindToFormGameDTO(game))} className="position-relative">
													{SELECT_GAME_TYPE_OPTIONS.find(g => g.value === game.type).label}
												</td>
												<td onClick={() => form.set(gameFindToFormGameDTO(game))} className="position-relative">
													{game.adversaryTeam.name}
												</td>
												<td
													onClick={() => {
														navigate('/analyze?id=' + game.id)
													}}
													style={{ cursor: 'pointer', color: ' green', fontWeight: 'bold' }}
												>
													Analisar
												</td>
												<td onClick={() => null} style={{ cursor: 'pointer', color: ' green', fontWeight: 'bold' }}>
													Relatório
												</td>
												<td>{game.status === 'C' ? 'CONCLUÍDO' : 'PENDENTE'}</td>
												<td className="bg-danger text-center" onClick={() => selectedGameIdToRemove.set(game.id)}>
													🗑️
												</td>
											</>
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</S.ManageGamesContainer>
			</div>
		</div>
	)
}

const gameFindToFormGameDTO = (game: GameFindDTO): CreateUpdateGameDTO => ({
	id: game.id,
	gender: game.gender,
	mainTeamId: game.mainTeam.id,
	categoryId: game.category.id,
	adversaryTeamId: game.adversaryTeam.id,
	url: game.url,
	type: game.type,
})
