import { useState } from '@hookstate/core'
import { useEffect, useRef } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { SELECT_COMMON_OPTIONS, SELECT_GAME_TYPE_OPTIONS, SELECT_GENDER_OPTIONS } from '../consts'
import { api } from '../services/api'
import { buildSelectOptions } from '../utils'
import { Loading } from './Loading'
import * as S from './PageReport.styles'
import { ReportComparePlayers } from './ReportComparePlayers'
import { ReportMovesChart } from './ReportMovesChart'
import { ReportMovesFootballField } from './ReportMovesFootballField'
import { ReportMovesTable } from './ReportMovesTable'

export const PageReport = () => {
	const selectGender = [...SELECT_GENDER_OPTIONS]

	const report = useState<ReportDTO | null>(null)
	const selectMainTeams = useState<SelectOption[]>([])
	const selectPlayers = useState<SelectOption[]>([])
	const selectComparePlayers = useState<SelectOption[]>([])
	const selectGameType = [...SELECT_GAME_TYPE_OPTIONS]
	const selectedPlayersCloseCount = useRef(0)
	const compareActive = useState(false)
	const isOpenModal = useState(false)
	const isSingle = useState(false)
	const isCollective = useState(true)
	const reportComparePlayers = useState([])
	const selectedChartType = useState<'pizza' | 'line'>('pizza')

	const filters = useState({
		gender: null,
		mainTeamId: null,
		type: null,
		players: [],
	})

	const loading = useState({
		players: true,
		teams: true,
		report: false,
	})

	useEffect(() => {
		console.log(filters.value.players.length)
		if (filters.value.players.length > 1) {
			console.log('zerando compare players')
			compareActive.set(true)
		}

		if (filters.value.players.length === 0) {
			console.log('zerando compare players')
			reportComparePlayers.set([])
			compareActive.set(false)
		}
	}, [filters.value.players.length])

	useEffect(() => {
		if (!isSingle.value) {
			compareActive.set(false)
			reportComparePlayers.set([])
		}
	}, [isSingle.value])

	useEffect(() => {
		api
			.findTeamsForSelect()
			.then(newTeams => selectMainTeams.set(buildSelectOptions(newTeams, { label: 'name', value: 'id' })))
			.finally(() => loading.merge({ teams: false }))

		api
			.getPlayersForSelect()
			.then(newPlayers => {
				selectPlayers.set(buildSelectOptions(newPlayers, { label: 'name', value: 'id' }))
				selectComparePlayers.set(buildSelectOptions(newPlayers, { label: 'name', value: 'id' }))
			})
			.finally(() => loading.merge({ players: false }))
	}, [])

	const generateReport = () => {
		loading.merge({ report: true })
		api
			.getReport(filters.value)
			.then(res => {
				report.set(res)
			})
			.finally(() => loading.merge({ report: false }))
	}

	async function handleComparePlayer(player) {
		const newFilter = filters

		newFilter.merge({ players: [player.value] })

		const response = await api.getComparePlayers(newFilter.value)

		reportComparePlayers.merge([response])
	}

	console.log('selectComparePlayers.value')
	console.log(selectComparePlayers.value)

	return (
		<S.PageContainer className="container-fluid">
			<S.FiltersContainer className="container">
				<div className="form-group">
					<label>Gênero</label>
					<Select
						isClearable
						{...SELECT_COMMON_OPTIONS}
						options={selectGender}
						value={selectGender.find(g => g.value === filters.value.gender) || null}
						onChange={newValue => filters.merge({ gender: newValue?.value || null })}
					/>
				</div>
				<div className="form-group">
					<label>Time</label>
					<Select
						isClearable
						{...SELECT_COMMON_OPTIONS}
						isLoading={loading.value.teams}
						options={selectMainTeams.value}
						value={selectMainTeams.value.find(t => t.value === filters.value.mainTeamId) || null}
						onChange={newValue => filters.merge({ mainTeamId: newValue?.value || null })}
					/>
				</div>
				<div className="form-group">
					<label>Tipo</label>
					<Select
						isClearable
						{...SELECT_COMMON_OPTIONS}
						options={selectGameType}
						value={selectGameType.find(c => c.value === filters.value.type) || null}
						onChange={newValue => filters.merge({ type: newValue?.value || null })}
					/>
				</div>
				{/* {!isSingle && (
					<div className="form-group">
						<label>Jogadores</label>
						<Select
							isClearable
							isMulti
							{...SELECT_COMMON_OPTIONS}
							closeMenuOnSelect={false}
							isLoading={loading.value.players}
							options={selectPlayers.value}
							value={selectPlayers.value.filter(p => filters.value.players.includes(p.value))}
							onChange={newValue => {
								newValue.map(nv => {
									handleComparePlayer(nv)
								})
								filters.merge({ players: newValue.map(nv => nv.value) })
							}}
						/>
					</div>
				)} */}
				<div className="form-group" style={{ display: 'grid', gridTemplateColumns: '100px 100px', gap: 10 }}>
					<div>
						<label>{/* <Loading show={loading.value.report} /> */}</label>
						<button
							className="btn btn-success d-block"
							disabled={loading.value.report}
							onClick={() => {
								isSingle.set(false)
								isCollective.set(true)
								filters.merge({ players: [] })
							}}
							style={{ backgroundColor: isCollective.value && '#ff751a ' }}
						>
							COLETIVA
						</button>
					</div>
					<div>
						<label>{/* <Loading show={loading.value.report} /> */}</label>
						<button
							className="btn btn-success d-block"
							disabled={loading.value.report}
							onClick={() => {
								isSingle.set(true)
								isCollective.set(false)
								filters.merge({ players: [] })
							}}
							style={{ backgroundColor: isSingle.value && '#ff751a ', marginRight: 10 }}
						>
							INDIVIDUAL
						</button>
					</div>
				</div>
				{isSingle.value && (
					<>
						<div className="form-group">
							<label>Jogadores</label>
							<Select
								isClearable
								isMulti
								{...SELECT_COMMON_OPTIONS}
								closeMenuOnSelect={false}
								isLoading={loading.value.players}
								options={selectComparePlayers.value}
								value={selectComparePlayers.value.filter(p => filters.value.players.includes(p.value))}
								onChange={newValue => {
									newValue.map(nv => {
										handleComparePlayer(nv)
									})
									filters.merge({ players: newValue.map(nv => nv.value) })
								}}
							/>
						</div>
						{/* <div className="form-group">
							
							<label>
								<Loading show={loading.value.report} />
							</label>
							<button className="btn btn-success d-block" disabled={compareActive} onClick={() => isOpenModal.set(true)}>
								GERAR
							</button>
						</div> */}
					</>
				)}

				<div className="form-group">
					{compareActive.value ? (
						<>
							<label>
								<Loading show={loading.value.report} />
							</label>
							<button className="btn btn-success d-block" onClick={() => isOpenModal.set(true)}>
								COMPARAR
							</button>
						</>
					) : (
						<>
							<label>
								<Loading show={loading.value.report} />
							</label>
							<button className="btn btn-success d-block" disabled={compareActive.value} onClick={() => generateReport()}>
								GERAR
							</button>
						</>
					)}
				</div>
			</S.FiltersContainer>
			{report.value ? (
				<>
					<S.ReportsContainer>
						<div>
							<ReportMovesTable report={report.value} />
						</div>
						<div className="ml-2">
							<div>
								<input
									type="radio"
									name="chart-type"
									id="chart-type-pizza"
									value="pizza"
									checked={selectedChartType.value === 'pizza'}
									onChange={() => selectedChartType.set('pizza')}
								/>
								<label htmlFor="chart-type-pizza">Gráfico Pizza</label>{' '}
								<input
									type="radio"
									name="chart-type"
									id="chart-type-line"
									value="line"
									checked={selectedChartType.value === 'line'}
									onChange={() => selectedChartType.set('line')}
								/>
								<label htmlFor="chart-type-line">Gráfico de Linhas</label>
							</div>
							<ReportMovesChart report={report.value} type={selectedChartType.value} />
						</div>
					</S.ReportsContainer>
					<ReportMovesFootballField report={report.value} />
				</>
			) : null}

			{isOpenModal.value && reportComparePlayers.value ? (
				<Modal
					isOpen={true}
					// onAfterOpen={afterOpenModal}
					ariaHideApp={false}
					onRequestClose={() => isOpenModal.set(false)}
				>
					<div style={{ display: 'grid', justifyContent: 'end' }}>
						<button onClick={() => isOpenModal.set(false)} style={{ backgroundColor: '#FFF', border: 0, fontWeight: 'bold', fontSize: 32 }}>
							X
						</button>
					</div>
					<hr />
					<ReportComparePlayers report={reportComparePlayers.value} />
				</Modal>
			) : null}
		</S.PageContainer>
	)
}
