import { useState as UseState }   from 'react'
import { useNavigate } from 'react-router-dom'
import { api } from '../services/api'
import { storeLoggedUser } from '../services/store'
import Logo from '../assets/logo.svg'
import { useState } from '@hookstate/core'

import '../styles/home.css'

export const PageLogin = () => {
	const navigation = useNavigate()
	const loggedUser = useState(storeLoggedUser)
	const username = useState('')
	const password = useState('')
	const [error, setError] = UseState(null)

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		setError('')
		api
			.login({
				username: username.value,
				password: password.value,
			})
			.then(user => {
				loggedUser.set(user)
				navigation('/')
			})
			.catch(error => {
				setError(error)
			}) 
	}

	return (

		<div className="">
			<div className="row">
				<div className=" col imagem d-none d-lg-block d-md-block">
				</div>
				<div className="col formulario">
					<div className="pt-5 mb-20 title">
						<img src={Logo} width={100} alt="Imagem" />
						<h1 className="text-center">Bem vindo a Latent</h1>
						<span style={{color: '#FF751A', fontWeight:'bold'}}>Youth of today, possibilities of tomorrow</span>
					</div>
					
					<div className="forms">
					<form onSubmit={handleSubmit}>
						<div className="mb-4">
							<label htmlFor="username">Usuário</label>
							<input value={username.value} onChange={e => username.set(e.target.value)} id="username" type="text" autoFocus className="form-control" />
						</div>
						<div className="mb-4">
							<label htmlFor="password">Senha</label>
							<input value={password.value} onChange={e => password.set(e.target.value)} id="password" type="password" className="form-control" />
						{error ? error.statusCode === 401 ? (
							<span className="error">Usuário ou senha incorreto</span>
						) : <span className="error">Erro ao efetuar o login. Tente novamente!</span> : ''	}
						</div>
						<div className="d-grid">
							<button type="submit" className="btn btn-primary">
								LOGIN
							</button>
						</div>
					</form>
					</div>
				</div>
			</div>
		</div>
		// <div className="mw-100 bg-white">
		// 	<div className="inline-flex vh-100">
		// 		<div className="d-inline-block w-50">
		// 				<img
		// 					className="w-100 vh-100"
		// 					src="https://images.pexels.com/photos/3621104/pexels-photo-3621104.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=50&w=340" alt="Imagem" />
		// 		</div>
		// 		<div className="d-inline-block w-50">
		// 			<div className="formulario w-50 aling-items-center">
		// 				<form onSubmit={handleSubmit}>
		// 					<div className="form-group">
		// 						<label htmlFor="username">Usuário</label>
		// 						<input value={username.value} onChange={e => username.set(e.target.value)} id="username" type="text" autoFocus className="form-control" />
		// 					</div>
		// 					<div className="form-group">
		// 						<label htmlFor="password">Senha</label>
		// 						<input value={password.value} onChange={e => password.set(e.target.value)} id="password" type="password" className="form-control" />
		// 					</div>
		// 					<button type="submit" className="btn btn-primary">
		// 						LOGIN
		// 					</button>
		// 				</form>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	)
}
