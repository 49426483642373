import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	height: 86px;
	padding: 48px 48px;

	div {
		flex-direction: row;

		ul {
			display: inline-flex;
			gap: 20px;

			li {
				list-style: none;
				list-style-type: none;
				font-size: 16px;
				font-weight: bold;

				a {
					text-decoration: none;
					color: white;
				}
			}
		}

		p {
			color: white;
			font-size: 16px;
			font-weight: bold;
		}
	}
`

export const Profile = styled.div`
	position: relative;
	padding-left: 12px;
	display: flex;
`

export const Menu = styled.div`
	::before {
		content: '';
		position: absolute;
		top: -8px;
		right: 19px;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 0px 8px 8px;
		border-color: transparent transparent rgba(32, 32, 36, 0.8);
	}

	position: absolute;
	right: 0px;
	top: calc(100% + 56px);
	z-index: 9;
	width: 200px;
	padding: 0px;
	background: rgba(32, 32, 36, 0.8);
	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 60%) 0px 5px 20px;
	transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
	align-items: center;

	div {
		text-align: center;
		padding: 16px;
		a {
			text-decoration: none;
			color: white;
		}
	}
`

export const ImgLogo = styled.div`
	display: flex;
	align-items: center;
	flex: 1 1 0%;
`
