import styled from 'styled-components'

export const Form = styled.div`
	display: flex;
	gap: 10px;
	margin-bottom: 24px;
	justify-content: center;
	align-items: center;
	width: 100%;

	& > div {
		flex-grow: 1;
	}
`

export const Video = styled.div`
	background-color: orange;
	height: 500px;
`

export const TableContent = styled.div`
	display: flex;
	max-width: '50%';
	table {
		border-spacing: 0px 8px;
	}
`

export const ContentTeam = styled.div`
	display: flex;
	margin-top: 24px;
	/* background-color: cyan; */
`
export const ContentTeamHeader = styled.div`
	width: 50%;
	display: flex;
	justify-content: space-between;
	/* background-color: beige; */
	table {
		width: 100%;
		border-spacing: 0px 8px;

		thead {
			text-align: center;
		}
		tbody {
			text-align: center;
			display: table-row-group;
			vertical-align: middle;
			border-color: inherit;

			td {
				padding: 4px;
			}
		}
	}
`
export const ContentTeamPlayers = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: '40%';
	margin-top: 24px;
	/* background-color: brown; */
`

export const ModalTeamsWrapper = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	flex-direction: row;
`

export const ModalTablesAndMapWrapper = styled.div`
	display: flex;
	gap: 15px;
	& > div {
		flex-grow: 1;
		flex-basis: 33%;
	}
`

export const ScrollableTable = styled.table`
	tbody {
		display: block;
		height: 760px;
		overflow: auto;
	}
	thead,
	tbody tr {
		display: table;
		width: 100%;
		table-layout: fixed; /* even columns width , fix width of table too*/
	}
	thead {
		width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
	}
	table {
		min-width: 400px;
	}
	td,
	th {
		text-align: center;
	}
`
