import { useState } from '@hookstate/core'
import { KonvaEventObject } from 'konva/lib/Node'
import React, { useEffect } from 'react'
import { Arrow, Layer, Stage } from 'react-konva'
import Campo from '../assets/campo.jpeg'
import { FOOTBALL_IMG_HEIGHT, FOOTBALL_IMG_WIDTH } from '../consts'

type FootballFieldProps = {
	startingArrow: ArrowCoordinates
	onArrowDrawFinish: (coordinates: ArrowCoordinates) => void
}

export const FootballField = (props: FootballFieldProps) => {
	const isDrawing = useState(false)
	const activeArrow = useState<ArrowCoordinates>(props.startingArrow)

	const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
		const { x, y } = e.target.getStage().getPointerPosition()

		activeArrow.set({
			startX: x,
			startY: y,
			endX: x,
			endY: y,
			areaStart: null,
			areaEnd: null,
		})

		isDrawing.set(true)
	}

	const handleMouseUp = (e: KonvaEventObject<MouseEvent>) => {
		const { x, y } = e.target.getStage().getPointerPosition()

		const newArrow: ArrowCoordinates = {
			startX: activeArrow.value.startX,
			startY: activeArrow.value.startY,
			endX: x,
			endY: y,
			areaStart: getAreaFromCoordenates(activeArrow.value.startX, activeArrow.value.startY),
			areaEnd: getAreaFromCoordenates(x, y),
		}

		activeArrow.set(newArrow)

		props.onArrowDrawFinish({ ...newArrow })

		console.log(x, y)

		isDrawing.set(false)
	}

	const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
		if (!isDrawing.value || !activeArrow.value) return

		const { x, y } = e.target.getStage().getPointerPosition()

		activeArrow.merge({
			endX: x,
			endY: y,
		})
	}

	useEffect(() => {
		activeArrow.set(props.startingArrow)
	}, [props.startingArrow])

	const arrows = activeArrow.value ? [activeArrow.value] : []

	return (
		<div>
			<Stage
				onMouseDown={handleMouseDown}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
				width={FOOTBALL_IMG_WIDTH}
				height={FOOTBALL_IMG_HEIGHT}
				style={{ marginLeft: 32, justifyContent: 'center', background: `url(${Campo}) no-repeat` }}
			>
				<Layer style={{ width: FOOTBALL_IMG_WIDTH, height: FOOTBALL_IMG_HEIGHT }}>
					{arrows.map(arrow => (
						<Arrow key={JSON.stringify(arrow)} points={[arrow.startX, arrow.startY, arrow.endX, arrow.endY]} fill="orange" stroke="orange" />
					))}
				</Layer>
			</Stage>
		</div>
	)
}

function getAreaFromCoordenates(x: number, y: number) {
	// left side
	if (x <= 193) {
		if (y <= 213) {
			return 10
		}

		if (y <= 393) {
			return 7
		}

		if (y <= 570) {
			return 4
		}

		return 1
	}

	// middle
	if (x <= 312) {
		if (y <= 213) {
			return 11
		}

		if (y <= 393) {
			return 8
		}

		if (y <= 570) {
			return 5
		}

		return 2
	}

	if (y <= 213) {
		return 12
	}

	if (y <= 393) {
		return 9
	}

	if (y <= 570) {
		return 6
	}

	return 3
}
