export const buildSelectOptions = <T>(list: T[], { label, value }: { label: keyof T; value: keyof T }): SelectOption[] =>
	list.map(el => ({ label: el[label] as unknown as string, value: el[value] as unknown as any }))

export const formatTime = time => {
	return new Date(time * 1000).toISOString().substring(11, 11 + 8)
}

export const formattedTimeToSeconds = (timeStr: string) => {
	const steps = timeStr.split(':')

	if (steps.length === 3) {
		return Number(steps[0]) * 60 * 60 + Number(steps[1]) * 60 + Number(steps[2])
	}

	if (steps.length === 2) {
		return Number(steps[0]) * 60 + Number(steps[1])
	}
}
