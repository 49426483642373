import { useState } from '@hookstate/core'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { PageHome } from './components/PageHome'
import { PageLogin } from './components/PageLogin'
import { PageProfile } from './components/PageProfile'
import { PageTeams } from './components/PageTeams'
import { PageSac } from './components/PageSac'
import { Redirect } from './components/Redirect'
import { api } from './services/api'
import { storeLoggedUser } from './services/store'

/// the css import order matters
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import { AnalyzeMatch } from './pages/AnalyzeMatch'
import { PageGame as PageGames } from './components/PageGames'
import { PageAnalyzeGame } from './components/PageAnalyzeGame'
import { PageReport } from './components/PageReport'
import { Header } from './components/Header'

function App() {
	const navigate = useNavigate()
	const loggedUser = useState(storeLoggedUser)

	useEffect(() => {
		api
			.loggedUser()
			.then(user => {
				loggedUser.set(user)
			})
			.catch(e => {
				loggedUser.set({
					username: '',
					isAdmin: false,
				})

				navigate('/login')
			})
	}, [])

	if (!loggedUser.value) {
		return <span>...</span>
	}

	// kept like this until found a way to redirect flawlessly
	if (!loggedUser.value.username) {
		return (
			<>
				<Toaster />
				<PageLogin />
			</>
		)
	}

	return (
		<>
			{loggedUser.value.username !== '' ? (
				<Header />
			) : null}
			<Toaster />
			<Routes>
				<Route path="/" element={<PageHome />} />
				<Route path="/login" element={<PageLogin />} />
				<Route path="/profile" element={<PageProfile />} />
				<Route path="/analyze" element={<PageAnalyzeGame />} />
				<Route path="/teams" element={<PageTeams />} />
				<Route path="/sac" element={<PageSac />} />
				<Route path="/games" element={<PageGames />} />
				<Route path="/report" element={<PageReport />} />
			</Routes>
		</>
	)
}

export default App
