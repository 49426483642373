import { useState } from '@hookstate/core'
import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'
import Select from 'react-select'
import TimeField from 'react-simple-timefield'
import { SELECT_GAME_TYPE_OPTIONS, SELECT_GENDER_OPTIONS } from '../consts'
import { api } from '../services/api'
import { toastError, toastSaving } from '../services/toasts'
import { formattedTimeToSeconds, formatTime } from '../utils'
import { FootballField } from './FootballField'
import * as S from './PageAnalyzeGame.styles'

export const PageAnalyzeGame = () => {
	const playerRef = useRef<ReactPlayer>(null)
	const isPlayingSequence = useState(false)
	const playingSequenceMoveIdx = useRef(-1)
	const game = useState<GameForAnalyzeDTO>(null)

	const genderSelected = useState<SelectOption>(null)
	const optionsGender = useState<SelectOption[]>(SELECT_GENDER_OPTIONS)

	const selectedMainTeam = useState<SelectOption>(null)
	const optionsTeams = useState<SelectOption[]>([])

	const typeSelected = useState<SelectOption>(null)
	const optionsType = useState<SelectOption[]>(SELECT_GAME_TYPE_OPTIONS)

	const adversarySelected = useState<SelectOption>(null)
	const optionsAdversary = useState<SelectOption[]>([])

	const selectedTeamIdForPlay = useState(0)

	const isOpenModal = useState(false)

	const listMovesTypes = useState<MoveTypeDTO[]>([])
	const isPlayRandom = useState(false)
	const moves = useState<MoveDTO[]>([])
	const isDisabled = useState(true)
	const selectedMoveIdToRemove = useState(0)

	const editingMove = useState<CreateOrUpdateMoveDTO>({
		id: null,
		timeBegin: null,
		timeEnd: null,
		startPositionX: null,
		startPositionY: null,
		endPositionX: null,
		endPositionY: null,
		areaStart: null,
		areaEnd: null,
		moveTypeId: null,
		gameId: null,
		playerId: null,
		customDescription: null,
	})

	useEffect(() => {
		if (!game.value?.id) return

		adversarySelected.set({
			value: game.value.adversaryTeam.id,
			label: game.value.adversaryTeam.name,
		})

		selectedMainTeam.set({
			value: game.value.mainTeam.id,
			label: game.value.mainTeam.name,
		})

		genderSelected.set({
			value: game.value.gender,
			label: game.value.gender === 'M' ? 'Masculino' : 'Feminino',
		})

		typeSelected.set(SELECT_GAME_TYPE_OPTIONS.find(type => type.value === game.value.type))

		api
			.getGameMoves(game.value.id)
			.then(newMoves => moves.set(newMoves))
			.catch(() => toastError('Erro ao carregar jogadas'))
	}, [game?.value?.id])

	useEffect(() => {
		api.findTeams().then(newTeams => {
			const teams = newTeams.map(team => ({
				value: team.id,
				label: team.name,
			}))

			optionsTeams.set(teams)
			optionsAdversary.set([...teams])
		})

		api.getMovesTypes().then(newMoves => {
			listMovesTypes.set(newMoves)
		})

		const paramId = Number(new URLSearchParams(window.location.search).get('id'))

		if (paramId) {
			handleFindGame(paramId)
		}
	}, [])

	function handlePlaySequence() {
		if (isPlayingSequence.value) {
			playerRef.current.getInternalPlayer().pauseVideo()
			isPlayingSequence.set(false)
			return
		}

		playingSequenceMoveIdx.current = moves.value.findIndex(move => move.selectedForPlay)

		if (playingSequenceMoveIdx.current < 0) {
			return toast('Selecione as jogadas para reproduzir')
		}

		playerRef.current.seekTo(moves.value[playingSequenceMoveIdx.current].timeBegin, 'seconds')
		playerRef.current.getInternalPlayer().playVideo()
		isPlayingSequence.set(true)
	}

	function handleVideoPause() {
		// if it's playing a sequence, do not add a move
		if (playingSequenceMoveIdx.current >= 0) return
		const start = playerRef.current.getCurrentTime() - 8 > 0 ? playerRef.current.getCurrentTime() - 8 : 0
		const end = playerRef.current.getCurrentTime() + 5

		editingMove.set({
			id: null,
			timeBegin: start,
			timeEnd: end,
			startPositionX: null,
			startPositionY: null,
			endPositionX: null,
			endPositionY: null,
			areaStart: null,
			areaEnd: null,
			moveTypeId: null,
			gameId: game.value.id,
			playerId: null,
			customDescription: null,
		})

		isPlayRandom.set(false)
		isOpenModal.set(true)

		setTimeout(() => playerRef.current.getInternalPlayer().pauseVideo(), 100)
	}

	function handleVideoProgress(progress: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) {
		if (playingSequenceMoveIdx.current < 0) return

		const move = moves.value[playingSequenceMoveIdx.current]

		// shouldn't happen...
		if (!move) return
		// if the move isn't done yet
		if (progress.playedSeconds < move.timeEnd) return

		const nextMoveIdx = moves.value.findIndex((move, idx) => idx > playingSequenceMoveIdx.current && move.selectedForPlay)

		if (nextMoveIdx >= 0) {
			const nextMove = moves.value[nextMoveIdx]

			// if it's close, then just let it play
			if (Math.abs(nextMove.timeBegin - progress.playedSeconds) > 1) {
				playerRef.current.seekTo(nextMove.timeBegin, 'seconds')
			}
		} else {
			playerRef.current.getInternalPlayer().pauseVideo()
		}

		isPlayingSequence.set(false)

		setTimeout(() => {
			playingSequenceMoveIdx.current = nextMoveIdx
		}, 100)
	}

	function handleCloseModal() {
		isOpenModal.set(false)
	}

	function handlePlayerGameInfoChange(playerId: number, name: keyof PlayerGameDTO, value: string, changeBoth: boolean = false) {
		const mainTeamPlayerGameIdx = game.value.mainTeam.playerGames.findIndex(pg => pg.player.id === playerId)

		const newValue = value === '' ? '' : Number(value)

		const newPartial: Partial<PlayerGameDTO> = {}

		if (name === 'shirtNumber') {
			newPartial.editingShirtNumber = newValue
		}

		if (name === 'timePlayed') {
			newPartial.editingTimePlayed = newValue
		}

		if (changeBoth) {
			newPartial[name] = newValue as any
		}

		if (mainTeamPlayerGameIdx >= 0) {
			game.mainTeam.playerGames[mainTeamPlayerGameIdx].merge(newPartial)
		} else {
			const adversaryTeamPlayerGameIdx = game.value.adversaryTeam.playerGames.findIndex(pg => pg.player.id === playerId)

			game.adversaryTeam.playerGames[adversaryTeamPlayerGameIdx].merge(newPartial)
		}
	}

	function handleSavePlayerGameInfo(playerGame: PlayerGameDTO): void {
		if (
			(playerGame.editingShirtNumber !== undefined &&
				playerGame.editingShirtNumber !== null &&
				playerGame.editingShirtNumber !== playerGame.shirtNumber) ||
			(playerGame.editingTimePlayed !== undefined && playerGame.editingTimePlayed !== null && playerGame.editingTimePlayed !== playerGame.timePlayed)
		) {
			toastSaving(
				api.updatePlayerGameInfo({
					playerId: playerGame.player.id,
					gameId: game.value.id,
					shirtNumber: playerGame.editingShirtNumber !== playerGame.shirtNumber ? playerGame.editingShirtNumber || null : undefined,
					timePlayed: playerGame.editingTimePlayed !== playerGame.timePlayed ? playerGame.editingTimePlayed || null : undefined,
					teamId: playerGame.teamId,
				}),
				{
					loading: 'Salvando informações de jogador',
					success: 'Informações de jogador salvas com sucesso',
				}
			).then(() => {
				handlePlayerGameInfoChange(playerGame.player.id, 'shirtNumber', playerGame.editingShirtNumber?.toString() || '', true)
				handlePlayerGameInfoChange(playerGame.player.id, 'timePlayed', playerGame.editingTimePlayed?.toString() || '', true)
			})
		}
	}

	function handleEditMoveClick(move: MoveDTO) {
		if (selectedMoveIdToRemove.value === move.id) return

		playerRef.current.getInternalPlayer().pauseVideo()

		editingMove.set({
			id: move.id,
			timeBegin: move.timeBegin,
			timeEnd: move.timeEnd,
			startPositionX: move.startPositionX,
			startPositionY: move.startPositionY,
			endPositionX: move.endPositionX,
			endPositionY: move.endPositionY,
			areaStart: move.areaStart,
			areaEnd: move.areaEnd,
			moveTypeId: move.moveType?.id || null,
			gameId: game.value.id,
			playerId: move.player?.id || null,
			customDescription: move.customDescription,
		})

		isPlayRandom.set(!!move.customDescription)

		if (move.player) {
			if (game.value.adversaryTeam.playerGames.find(pg => pg.player.id === move.player?.id)) {
				selectedTeamIdForPlay.set(game.value.adversaryTeamId)
			} else {
				selectedTeamIdForPlay.set(game.value.mainTeamId)
			}
		}

		isOpenModal.set(true)
	}

	async function handleSaveMoves() {
		if (isPlayRandom.value) {
			if (!editingMove.value.customDescription) return toastError('Digite uma descrição personalizada')
		} else {
			if (!editingMove.value.playerId) return toastError('Selecione um jogador')
			if (!editingMove.value.moveTypeId) return toastError('Selecione uma jogada')
		}

		if (editingMove.value.timeBegin > editingMove.value.timeEnd) return toastError('Tempo de início maior que fim')

		try {
			const savedMove = await toastSaving(api.createOrUpdateMove(editingMove.value), {
				loading: 'Salvando Jogada',
				success: 'Jogada salva com sucesso!',
			})

			if (editingMove.value.id) {
				const idx = moves.value.findIndex(move => move.id === editingMove.value.id)
				moves[idx].set(savedMove)
			} else {
				moves.merge([savedMove])
			}
			moves.set(prev => prev.sort((a, b) => a.timeBegin - b.timeBegin))
		} catch (error) {
			toastError(error)
		}

		handleCloseModal()
	}

	async function startPlay(move) {
		playerRef.current.seekTo(move.timeBegin, 'seconds')
	}

	async function handleFindGame(id?: number) {
		const newGame = await api.getGameForAnalyze({
			id: id?.toString(),
			gender: genderSelected.value?.value,
			adversaryTeamId: adversarySelected.value?.value,
			mainTeamId: selectedMainTeam.value?.value,
			type: typeSelected.value?.value,
		})

		if (newGame) {
			game.set(newGame)
			isDisabled.set(false)
			selectedTeamIdForPlay.set(newGame.mainTeam.id)
		}
	}

	async function handleConcludeGame() {
		// toastSaving(api.createOrUpdateGame(form.value), { loading: 'Salvando...', success: 'Salvo com sucesso' })
		await toastSaving(api.updateStatusGame(game.value.id, 'C'), { loading: 'Alterando Status', success: 'Alterado Status do Jogo com sucesso!' })
	}

	function deleteMove(moveId: number) {
		toastSaving(api.deleteMove(moveId), { loading: 'Removendo jogada', success: 'Jogada removida com sucesso' }).then(() => {
			moves.set(prev => prev.filter(move => move.id !== moveId))
			selectedMoveIdToRemove.set(0)
		})
	}

	const selectedTeamForPlay =
		game.value && selectedTeamIdForPlay.value
			? selectedTeamIdForPlay.value === game.value.mainTeam.id
				? game.value.mainTeam
				: game.value.adversaryTeam
			: null

	const selectedPlayerForPlay =
		selectedTeamForPlay && editingMove.value.playerId
			? selectedTeamForPlay.playerGames.find(playerGame => playerGame.player.id === editingMove.value.playerId).player
			: null

	const selectedMoveTypeForPlay = editingMove.value.moveTypeId
		? listMovesTypes.value.find(moveType => moveType.id === editingMove.value.moveTypeId)
		: null

	return (
		<div className="container">
			<hr />
			<h3 className="text-center">ANALISE DE PARTIDA</h3>
			<hr />

			<S.Form>
				<div className="form-group">
					<label htmlFor="gender">Gênero</label>
					<Select isClearable name="gender" options={optionsGender.value} value={genderSelected.value} onChange={text => genderSelected.set(text)} />
				</div>
				<div className="form-group">
					<label htmlFor="team">Time</label>
					<Select isClearable name="team" options={optionsTeams.value} value={selectedMainTeam.value} onChange={text => selectedMainTeam.set(text)} />
				</div>
				<div className="form-group">
					<label htmlFor="type">Tipo</label>
					<Select isClearable name="type" options={optionsType.value} value={typeSelected.value} onChange={text => typeSelected.set(text)} />
				</div>
				<div className="form-group">
					<label htmlFor="adversary">Adversário</label>
					<Select
						isClearable
						name="adversary"
						options={optionsAdversary.value}
						value={adversarySelected.value}
						onChange={text => adversarySelected.set(text)}
					/>
				</div>
				<div className="form-group">
					<label htmlFor=""> </label>
					<button onClick={() => handleFindGame()} type="button" className="d-block btn btn-success">
						CARREGAR
					</button>
				</div>
				<div className="form-group">
					<label htmlFor=""> </label>
					<button
						onClick={() => handleConcludeGame()}
						type="button"
						className="d-block btn btn-success"
						disabled={isDisabled.value}
						style={{ cursor: isDisabled.value ? 'not-allowed' : 'pointer' }}
					>
						CONCLUIR JOGO
					</button>
				</div>
			</S.Form>

			{game.value ? (
				<>
					<S.Video>
						<ReactPlayer
							onPause={handleVideoPause}
							onProgress={handleVideoProgress}
							progressInterval={1}
							ref={playerRef}
							width={'100%'}
							height={'100%'}
							url={game.value?.url}
							playing={true}
							controls={true}
						/>
					</S.Video>

					<div>
						{moves.value.length > 0 ? (
							<>
								<hr />
								<h3 className="text-center">LISTA DE JOGADAS</h3>
								<hr />

								<div>
									<div className="table-teams-wrapper">
										<table className="table table-sm table-striped table-hover table-with-selected-indicator">
											<thead>
												<tr>
													<th>
														<button className="btn btn-sm btn-info" onClick={handlePlaySequence}>
															{isPlayingSequence.value ? 'parar' : 'play'}
														</button>
													</th>
													<th>Inicio</th>
													<th>Fim</th>
													<th>Jogador</th>
													<th>Jogada</th>
													<th>Ver jogada</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{moves.value.map((move, i) => (
													<tr key={move.id} onClick={() => handleEditMoveClick(move)}>
														{selectedMoveIdToRemove.value === move.id ? (
															<td colSpan={9}>
																<div className="d-flex justify-content-between">
																	<button type="button" className="btn btn-sm btn-danger" onClick={() => deleteMove(move.id)}>
																		Confirmar exclusão!
																	</button>
																	<button
																		type="button"
																		className="btn btn-sm btn-success"
																		onClick={() => setTimeout(() => selectedMoveIdToRemove.set(0), 100)}
																	>
																		Cancelar
																	</button>
																</div>
															</td>
														) : (
															<>
																<td
																	className="cursor-pointer position-relative v-middle"
																	onClick={e => {
																		e.stopPropagation()
																		moves[i].merge({ selectedForPlay: !move.selectedForPlay })
																	}}
																>
																	<i className="selected-indicator bigger-top" />
																	<input type="checkbox" checked={move.selectedForPlay} />
																</td>
																<td>{formatTime(move.timeBegin)}</td>
																<td>{formatTime(move.timeEnd)}</td>
																<td>{move.player?.name || '-'}</td>
																<td>{move.moveType?.name || move.customDescription || '-'}</td>
																<td>
																	<div>
																		<button
																			onClick={e => {
																				e.stopPropagation()
																				startPlay(move)
																			}}
																			className="btn btn-sm btn-info"
																		>
																			Iniciar jogada
																		</button>
																	</div>
																</td>
																<td className="bg-danger text-center" onClick={() => selectedMoveIdToRemove.set(move.id)}>
																	🗑️
																</td>
															</>
														)}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</>
						) : null}
					</div>

					<Modal isOpen={isOpenModal.value} ariaHideApp={false} onRequestClose={() => handleCloseModal()}>
						<div className="modal-header" style={{ flexDirection: 'row', height: 32, paddingBottom: 20 }}>
							<h3 className="text-center" style={{ color: '#ff751a' }}>
								Salvar Jogada
							</h3>
							<button onClick={() => handleCloseModal()} style={{ backgroundColor: '#FFF', border: 0, fontWeight: 'bold' }}>
								X
							</button>
						</div>
						<div style={{ marginTop: 16 }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
								<div>
									<span>
										Jogada: de{' '}
										<TimeField
											value={formatTime(editingMove.value.timeBegin || 0)}
											onChange={e => editingMove.merge({ timeBegin: formattedTimeToSeconds(e.target.value) })}
											style={{ width: 70 }}
											showSeconds
										/>{' '}
										até{' '}
										<TimeField
											value={formatTime(editingMove.value.timeEnd || 0)}
											onChange={e => editingMove.merge({ timeEnd: formattedTimeToSeconds(e.target.value) })}
											style={{ width: 70 }}
											showSeconds
										/>
										, {selectedPlayerForPlay?.name || '-'}, {selectedMoveTypeForPlay?.name || editingMove.value.customDescription || '-'}
									</span>

									<S.ModalTeamsWrapper>
										<h3
											onMouseEnter={() => {
												selectedTeamIdForPlay.set(game.value.mainTeam.id)
												editingMove.merge({
													playerId: null,
												})
											}}
											className={`p-1 cursor-pointer ${selectedTeamIdForPlay.value === game.value.mainTeam.id && 'bg-orange'}`}
										>
											{game.value.mainTeam.name}
										</h3>
										<span>x</span>
										<h3
											onMouseEnter={() => {
												selectedTeamIdForPlay.set(game.value.adversaryTeam.id)
												editingMove.merge({
													playerId: null,
												})
											}}
											className={`p-1 cursor-pointer ${selectedTeamIdForPlay.value === game.value.adversaryTeam.id && 'bg-orange'}`}
										>
											{game.value.adversaryTeam.name}
										</h3>
									</S.ModalTeamsWrapper>
								</div>
								<div>
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											onChange={e => {
												editingMove.merge({
													playerId: null,
												})
												isPlayRandom.set(e.target.checked)
											}}
											id="flexCheckChecked"
											checked={isPlayRandom.value}
										/>
										<label className="form-check-label" htmlFor="flexCheckChecked">
											Jogada aleatória
										</label>
									</div>

									<div className="mt-2">
										<button onClick={handleSaveMoves} type="button" className="d-block btn btn-success">
											CONFIRMAR
										</button>
									</div>
								</div>
							</div>

							<hr />

							<S.ModalTablesAndMapWrapper>
								{isPlayRandom.value ? (
									<>
										<div></div>
										<div>
											<S.ScrollableTable className="table table-sm table-striped">
												<thead>
													<tr>
														<th>Jogada</th>
													</tr>
												</thead>
												<tbody>
													<tr
														key={0}
														onMouseEnter={() => editingMove.merge({ moveTypeId: 0 })}
														className={editingMove.value.moveTypeId === 0 ? 'bg-orange' : ''}
													>
														<td>
															<input
																type="text"
																className="w-100 text-center"
																value={editingMove.value.customDescription || ''}
																onChange={e => editingMove.merge({ customDescription: e.target.value })}
																placeholder="<< descrição customizada >>"
															/>
														</td>
													</tr>
												</tbody>
											</S.ScrollableTable>
										</div>
									</>
								) : (
									<>
										<div>
											<S.ScrollableTable className="table table-sm table-striped">
												<thead>
													<tr>
														<th>Tempo</th>
														<th>Jogador</th>
														<th>Número</th>
													</tr>
												</thead>
												<tbody>
													{selectedTeamForPlay?.playerGames.map(playerGame => (
														<tr
															key={playerGame.player.id}
															onMouseEnter={() =>
																editingMove.merge({
																	playerId: playerGame.player.id,
																})
															}
															onMouseLeave={() => handleSavePlayerGameInfo(playerGame)}
															className={editingMove.value.playerId === playerGame.player.id ? 'bg-orange' : ''}
														>
															<td>
																<input
																	type="number"
																	className="w-100 text-center"
																	value={(playerGame.editingTimePlayed ?? playerGame.timePlayed) || ''}
																	onChange={e => handlePlayerGameInfoChange(playerGame.player.id, 'timePlayed', e.target.value)}
																	onBlur={() => handleSavePlayerGameInfo(playerGame)}
																/>
															</td>
															<td>{playerGame.player.name}</td>
															<td>
																<input
																	type="number"
																	className="w-100 text-center"
																	value={(playerGame.editingShirtNumber ?? playerGame.shirtNumber) || ''}
																	onChange={e => handlePlayerGameInfoChange(playerGame.player.id, 'shirtNumber', e.target.value)}
																	onBlur={() => handleSavePlayerGameInfo(playerGame)}
																/>
															</td>
														</tr>
													))}
												</tbody>
											</S.ScrollableTable>
										</div>
										<div>
											<S.ScrollableTable className="table table-sm table-striped">
												<thead>
													<tr>
														<th>Jogada</th>
													</tr>
												</thead>
												<tbody>
													{listMovesTypes.value.map(moveType => (
														<tr
															key={moveType.id}
															onMouseEnter={() => editingMove.merge({ moveTypeId: moveType.id })}
															className={editingMove.value.moveTypeId === moveType.id ? 'bg-orange' : ''}
														>
															<td>{moveType.name}</td>
														</tr>
													))}
												</tbody>
											</S.ScrollableTable>
										</div>
									</>
								)}
								<div>
									<FootballField
										onArrowDrawFinish={newCoordinates =>
											editingMove.merge({
												startPositionX: newCoordinates.startX,
												startPositionY: newCoordinates.startY,
												endPositionX: newCoordinates.endX,
												endPositionY: newCoordinates.endY,
												areaStart: newCoordinates.areaStart,
												areaEnd: newCoordinates.areaEnd,
											})
										}
										startingArrow={{
											startX: editingMove.value.startPositionX,
											startY: editingMove.value.startPositionY,
											endX: editingMove.value.endPositionX,
											endY: editingMove.value.endPositionY,
											areaStart: editingMove.value.areaStart,
											areaEnd: editingMove.value.areaEnd,
										}}
									/>
								</div>
							</S.ModalTablesAndMapWrapper>
						</div>
					</Modal>
				</>
			) : null}
		</div>
	)
}
