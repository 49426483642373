import styled from 'styled-components'

export const FixedWrapper = styled.div`
	position: fixed;
	right: 0;
	transform: translateX(460px);
	transition: all 0.5s ease-in-out;

	&:hover {
		transform: translateX(0px);
	}
`
