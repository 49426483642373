import { Chart } from 'react-google-charts'

type ReportMovesChartProps = {
	report: ReportDTO
	type: 'line' | 'pizza'
}

export const ReportMovesChart = ({ report, type }: ReportMovesChartProps) => {
	if (type === 'line') {
		const optionsLine = {
			chart: {
				// title: 'Gráfico de linha das jogadas',
				chartArea: { left: 0, top: 0 },
			},
		}

		// const dataLine = [
		// 	['Jogadas', 'Passe Certo Pressionado', 'Passe Errado Pressionado', 'Intercepção', 'Roubada de Bola'],
		// 	['Gremio', 10, 8, 8, 3],
		// 	['Inter', 3, 14, 4, 2],
		// ]

		let gamesLine = []
		let movesGame = []
		let moveName = []
		moveName.push('Jogadas')
		for (var a in report.games) {
			movesGame = []
			movesGame.push(report.games[a].name)

			for (var index in report.moves) {
				for (var i in report.moves[index]) {
					for (var x in report.moves[index][i]) {
						if (report.moves[index][i][x].moveTypeId >= 0) {
							if (a === '0') {
								moveName.push(report.moves[index][i][x].name)
							}
							movesGame.push(report.moves[index][i][x].games[a])
						}
					}
				}
			}
			if (a === '0') {
				gamesLine.push(moveName)
			}
			gamesLine.push(movesGame)
		}

		return <Chart chartType="Line" width={window.innerWidth / 2} height={window.innerHeight - 100} data={gamesLine} options={optionsLine} />
	} else {
		const optionsPie = {
			title: '',
			chartArea: { left: 0, top: 0 },
		}

		//  const data = [
		// 	["Jogadas', 'Quantidade"],
		// 	["Passe Certo Pressionado", 11],
		// 	["Roubada de Bola", 2],
		// 	["Cruzamento no Alvo", 2],
		//   ]

		let movesPie = []
		movesPie.push(['Jogadas', 'Quantidade'])

		for (var index in report.moves) {
			for (var i in report.moves[index]) {
				for (var x in report.moves[index][i]) {
					if (report.moves[index][i][x].moveTypeId >= 0) {
						movesPie.push([report.moves[index][i][x].name, report.moves[index][i][x].total])
					}
				}
			}
		}

		return <Chart chartType="PieChart" width={window.innerWidth / 2} height={window.innerHeight - 100} data={movesPie} options={optionsPie} />
	}
}
