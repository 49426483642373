import { useState } from '@hookstate/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { storeLoggedUser } from '../services/store'
import * as S from './Header.styles'

export function Header() {
	const loggedUser = useState(storeLoggedUser)
	const active = useState(false)

	return (
		<S.Container style={{ background: loggedUser.value.account.color }}>
			<div>
				<ul>
					<li>
						<Link to="/games">CRIAR PARTIDAS</Link>
					</li>
					<li>
						<Link to="/analyze">ANALISAR PARTIDAS</Link>
					</li>
					<li>
						<Link to="/report">VER RELATÓRIOS</Link>
					</li>
				</ul>
			</div>
			<S.Profile>
				<button style={{ background: 'transparent', border: 0, cursor: 'pointer' }} onFocus={() => active.set(true)} onBlur={() => active.set(false)}>
					<p>MINHA CONTA</p>
				</button>
				<S.Menu style={{ opacity: `${active.value ? 1 : 0}`, visibility: `${active.value ? 'visible' : 'hidden'}` }}>
					<div>
						<Link to="/profile">Meu perfil</Link>
					</div>
					<div>
						<Link to="/teams">CRIAR TIME</Link>
					</div>
					<div>
						<Link to="/sac">SAC</Link>
					</div>
				</S.Menu>
			</S.Profile>
		</S.Container>
	)
}
