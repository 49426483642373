import styled from 'styled-components'

export const Table = styled.table`
	font-family: 'Rubik', 'Roboto', sans-serif;
	font-size: 14px;

	thead {
		th {
			text-align: center;
			vertical-align: middle;
		}
	}

	td,
	th {
		text-align: center;
		border: 1px solid black;
		padding: 1px 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.header {
		td {
			height: 24px;
		}
	}

	.empty {
		border: none !important;
		background-color: white !important;
		padding: 1px;
	}

	.status-V {
		background-color: #34a853;
		color: white;
	}

	.status-D {
		background-color: #ff0000;
		color: white;
	}

	.status-E {
		background-color: #efefef;
		color: black;
	}

	.bg-orange {
		background-color: #ff5c00;
		color: white;
	}

	.bg-black {
		background-color: #212529;
		color: white;
	}

	.bg-total {
		background-color: #d9d9d9;
	}

	.bg-percentage {
		background-color: #b7b7b7;
	}

	.bg-percentage-empty {
		background-color: #666666;
	}

	.vertical-title {
		text-orientation: upright;
		writing-mode: vertical-rl;
		vertical-align: middle;
		font-weight: bold;
		width: 32px;
	}
`
