import React from 'react'
import { Layer, Stage } from 'react-konva'
import Campo from '../assets/campo.jpeg'
import { FOOTBALL_IMG_HEIGHT, FOOTBALL_IMG_WIDTH } from '../consts'
import { ArrowComponent } from './ArrowComponent'
import * as S from './ReportMovesFootballField.styles'

type ReportMovesChartProps = {
	report: ReportDTO
}

export const ReportMovesFootballField = ({ report }: ReportMovesChartProps) => {
	return (
		<S.FixedWrapper
			style={{
				// ideally it would be 180px, but if the screen is too small, then makes sure to not hide the bottom of the field
				top: Math.min(Math.floor(window.innerHeight - FOOTBALL_IMG_HEIGHT), 180) / 2 + 30,
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'cyan' }}>
				<div style={{ background: `url(${Campo}) no-repeat`, backgroundSize: 'contain' }}>
					<Stage width={FOOTBALL_IMG_WIDTH} height={FOOTBALL_IMG_HEIGHT}>
						<Layer style={{ width: FOOTBALL_IMG_WIDTH, height: FOOTBALL_IMG_HEIGHT }}>
							{report.moves.fieldPlayers.map((move, i) => {
								return move.map((m, j) => {
									const mt = report.moves.fieldPlayers[i][j]
									if (mt.startX !== null) {
										const points = [mt.startX, mt.startY, mt.endX, mt.endY]
										return <ArrowComponent points={points} />
									} else {
										return null
									}
								})
							})}
						</Layer>
					</Stage>
				</div>
			</div>
		</S.FixedWrapper>
	)
}
