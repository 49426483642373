import { useState } from '@hookstate/core'
import React, { useRef, useEffect } from 'react'
import { api } from '../services/api'
import Sac from './Sac'

export const PageSac = () => {
	const sac = useState<Sac[]>([])
	useEffect(() => {
		api.getSac().then(newSac => {
			sac.set(newSac.map(t => ({ id: t.id, question: t.question, response: t.response })))
		})
	}, [])

	const setting = useState<Setting>({ videoLink: '' })
	useEffect(() => {
		api.getSetting().then(newSetting => {
			setting.set(newSetting)
		})
	}, [])

	return (
		<div className="container">
			<h1>BEM VINDO A PLATAFORMA</h1>
			<div className="responsive-video">
				<iframe
					width="560"
					height="315"
					permissions-policy="interest-cohort"
					src={setting.videoLink.value}
					frameBorder="0"
					allow="accelerometer"
				></iframe>
			</div>
			<h1>PERGUNTAS FREQUENTES</h1>
			{sac.value.map(sac => (
				<div key={sac.id}>
					<Sac question={sac.question} response={sac.response} />
				</div>
			))}
		</div>
	)
}
