import { useState } from '@hookstate/core'
import React, { useRef, useEffect } from 'react'
import '../styles/sac.css'

function Sac(props) {
	const active = useState('')
	const heigth = useState('0px')
	const rotate = useState('accordion_icon')

	const content = useRef(null)

	function toggleAccordion() {
		active.set(active.value === '' ? ' active' : '')
		heigth.set(active.value === ' active' ? `${content.current.scrollHeight}px` : '0px')
		rotate.set(active.value === ' active' ? 'accordion_icon rotate' : 'accordion_icon')
	}

	function chevron() {
		return (
			<svg className={rotate.value} height={15} width={10} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
				<path
					fill={'#777'}
					d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
				/>
			</svg>
		)
	}

	return (
		<div className="accordion_section">
			<button className={`accordion${active.value}`} onClick={toggleAccordion}>
				<p className="accordion_title">{props.question}</p>
				{chevron()}
			</button>
			<div ref={content} style={{ maxHeight: `${heigth.value}` }} className="accordion_content">
				<div className="accordion_text" dangerouslySetInnerHTML={{ __html: props.response }} />
			</div>
		</div>
	)
}

export default Sac
